<template>
  <div class="main">
    <HelperBox class="main__helperbox" />
    <div class="main__nav">
      <TheNav />
    </div>
    <div id="main__content" ref="appDom">
      <router-view name="helper"></router-view>
    </div>
  </div>
  <TheOverlayMain v-if="getOverlayState"></TheOverlayMain>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { useStore } from "vuex";
import TheNav from "@/components/common/navigation/TheNav";
import TheOverlayMain from "@/components/common/overlay/TheOverlayMain";
import HelperBox from "@/components/helpers/HelperBox";

import {
  nextTick,
  computed,
  watchEffect,
  onMounted,
  onUpdated,
  ref,
} from "vue";

export default {
  name: "App",
  components: { TheNav, TheOverlayMain, HelperBox },
  setup() {
    const DOMLOADED = ref(true);
    const checkingApp = ref(true);
    const store = useStore();
    // lets define some computed const to watch things. so its more readble
    const getOverlayState = computed(() => store.state.isOverlayOpen);
    const getMenuState = computed(() => store.state.isMenuOpen);

    const appDom = ref(null);

    function resetApp() {
      console.log("appDom = ", appDom);
      appDom.value.style.height = 0;
      appDom.value.style.visibility = "hidden";
      appDom.value.style.overflow = "hidden";
    }
    function updateScrollTrigger() {
      console.error("updateScrollTrigger!");
      if (checkingApp.value) {
        console.error("INSIDE!");
        checkingApp.value = false;
        setTimeout(function() {
          appDom.value.style.visibility = "visible";
          appDom.value.style.height = "inital";
          appDom.value.style.overflow = "visible";

          appDom.value.style.visibility = "visible";
          ScrollTrigger.getAll().forEach((element) => {
            element.update();
            element.refresh();
          });
        }, 100);
      }
    }
    nextTick(() => {
      console.log("nextTick MAIN <-----");
      updateScrollTrigger;
    });

    watchEffect(() => {
      if (store.state.isOverlayOpen) {
        // document.body.style.overflow = "hidden";
      } else {
        // document.body.style.overflow = "auto";
      }
    });

    onUpdated(() => {
      console.log("onUpdated MAIN <-----");
      checkingApp.value = true;
      resetApp();
      updateScrollTrigger();
    });
    onMounted(() => {
      console.log("onMounted MAIN <-----");
      document.addEventListener("DOMContentLoaded", () => {
        console.log("DOMLOADED MAIN<-----------");
        DOMLOADED.value = true;
        resetApp();
        updateScrollTrigger();
      });
      window.addEventListener("load", function() {
        console.log("LOADED MAIN<-----------");
      });
      resetApp();
      updateScrollTrigger();
    });
    return {
      store,
      getOverlayState,
      getMenuState,
      checkingApp,
      appDom,
      updateScrollTrigger,
    };
  },
};
</script>

<style lang="scss">
.main {
  width: 100%;
  height: 100%;
  display: block;
}

.main__nav {
  z-index: 100;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
.main__box {
  position: relative;
  z-index: 0;
}

.main__mainbar {
  height: 100%;
}

main {
  min-height: unset;
}
#main__content {
  padding-top: $navi-height-desktop;
  top: 0;
  left: 0;
  position: relative;
  width: 100%;
  height: 100%;

  @media screen and (min-width: $medium) {
    // padding-top: $navi-height-desktop;
  }
}
</style>
