<template>
  <div
    class="navbtn"
    :class="{ isActive: isSpecial || onMouseEnter }"
    @click="onClick"
    @mouseenter="onMouseEnter = true"
    @mouseleave="onMouseEnter = false"
  >
    <div class="navbtn__hover"></div>
    <div class="navbtn__text">
      <slot></slot>
      <div class="navbtn__marker"></div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
export default {
  props: { dataname: String },
  setup(props) {
    const store = useStore();
    const onMouseEnter = ref(false);
    const publicPath = process.env.BASE_URL;

    const isBtn = ref(props.dataname);

    function onClick() {
      store.commit("setMenuState", false);
    }

    const isSpecial = computed(() => {
      return store.state.isActiveRoute == publicPath + isBtn.value;
    });

    return { onMouseEnter, isSpecial, store, isBtn, onClick };
  },
};
</script>

<style lang="scss">
.navbtn {
  position: relative;
  background-color: white;
  padding: 0 40px;

  &.isActive {
    background-color: black;
  }
}

.navbtn__text {
  font-weight: 900;
  position: relative;
  color: inherit;
  letter-spacing: 1.26px;
  line-height: 14vw;
  text-align: center;

  font-size: 12vw;

  font-family: $font-family-herohl;

  @media screen and (min-width: $medium) {
    text-align: center;
    font-size: 90px;
    line-height: 100px;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
  }
  @media screen and (min-width: $large) {
    text-align: center;
    font-size: 120px;
    line-height: 150px;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
  }
  @media screen and (min-width: $xlarge) {
  }
  @media screen and (min-width: $xxlarge) {
  }

  .isActive & {
    color: white;
  }
}

.navbtn__hover {
  position: absolute;
  left: -5px;
  right: -5px;
  top: 0;
  bottom: 0;
  background-color: white;
  transition: 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  overflow: hidden;

  opacity: 1;
  @media screen and (min-width: $medium) {
    left: -20px;
    right: -20px;
    top: 0;
    bottom: 0;
  }

  .is-mobile & {
    display: none;
  }
  .isActive & {
    background-color: black;
  }
}
</style>
